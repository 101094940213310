@tailwind base;
@tailwind components;
@tailwind utilities;


.mq-math-mode {
    min-width: 100%;
    margin: 0px 10px
}



.input-invalid {
    animation: shake .3 linear;
    -webkit-animation: shake .3s linear;
}

@keyframes shake {

    8%,
    41% {
        -webkit-transform: translateX(-10px);
    }

    25%,
    58% {
        -webkit-transform: translateX(10px);
    }

    75% {
        -webkit-transform: translateX(-5px);
    }

    92% {
        -webkit-transform: translateX(5px);
    }

    0%,
    100% {
        -webkit-transform: translateX(0);
    }
}